import { FC, useEffect } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import Page from '../../components/Page'
import PrescriberProfilePage from '../PrescriberProfilePage/PrescriberProfilePage'
import OrganizationProfilePage from '../OrganizationProfilePage'
import { SubscriptionInfo } from '../Billing/SubscriptionInfo'
import { RootState } from '../../app/store'
import { useAppDispatch, useAppSelector } from '../../app/typedReduxHooks'
import { BillingClient } from '../Billing/BillingSlice'
import { OrganizationAdminProfilePage } from '../../features/organization-admin/OrganizationAdminProfilePage'

const SettingsPage: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const organizationId = useAppSelector(
    (state: RootState) => state.prescriber?.prescriber?.organization.id
  )
  const organizationSettings = useAppSelector((state) => state.organization?.data?.settings)

  const prescriberId = useAppSelector((state) => state.prescriber.prescriber?.id)
  const organizationAdmin = useAppSelector((state) => state.organizationAdmin.profile)
  const subscription = useAppSelector((state: RootState) => state.billing?.subscription.data)
  useEffect(() => {
    if (!subscription && organizationId && prescriberId) {
      dispatch(BillingClient.getSubscription({ prescriberId, organizationId }))
    }
  }, [organizationId, dispatch])

  return (
    <Page>
      <Tabs size="lg">
        <TabList>
          {prescriberId ? <Tab fontWeight="bold">Prescriber Profile</Tab> : null}
          {organizationAdmin ? <Tab fontWeight="bold">Admin Profile</Tab> : null}
          {organizationSettings?.enableUpdateOrganization ? (
            <Tab fontWeight="bold">Organisation</Tab>
          ) : null}
          {subscription?.stripeSubscriptionId && <Tab fontWeight="bold">Subscription</Tab>}
        </TabList>
        <TabPanels>
          {prescriberId ? (
            <TabPanel>
              <PrescriberProfilePage />
            </TabPanel>
          ) : null}
          {organizationAdmin ? (
            <TabPanel>
              <OrganizationAdminProfilePage />
            </TabPanel>
          ) : null}
          {organizationSettings?.enableUpdateOrganization ? (
            <TabPanel>
              <OrganizationProfilePage />
            </TabPanel>
          ) : null}
          {subscription?.stripeSubscriptionId && (
            <TabPanel>
              <SubscriptionInfo subscription={subscription} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Page>
  )
}
export default SettingsPage
