import { Button, Flex, Heading, SimpleGrid, StackDivider, VStack } from '@chakra-ui/react'
import { Subscription } from '../../types'
import DetailDisplay from '../OrganizationProfilePage/components/DetailDisplay'
import { format } from 'date-fns'
import { Fragment, useEffect, useState } from 'react'
import { BillingClient } from './BillingSlice'
import useApi from '../../common/utils/api/useApi'

export const SubscriptionInfo = ({ subscription }: { subscription: Subscription }) => {
  const [billingPeriodEndDate, setBillingPeriodEndDate] = useState('')
  const [subscriptionStartDate, setSubscriptionStartDate] = useState('')
  useEffect(() => {
    setBillingPeriodEndDate(format(new Date(subscription.validTo), 'MMMM d, yyyy'))
    setSubscriptionStartDate(format(new Date(subscription.startAt), 'MMMM d, yyyy'))
  }, [subscription])

  const { trigger: triggerCreateCustomerPortalSession, loading } = useApi(
    BillingClient.createCustomerPortalSession,
    {
      onSuccess: (checkoutSessionUrl: string) => {
        window.location.href = checkoutSessionUrl
      },
      onFailure: () => {
        if (process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL) {
          window.location.href = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL
        }
      },
    }
  )

  return (
    <Fragment>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={2}
        align="stretch"
        width={['100%', '100%', '70%']}
      >
        <Flex justify="space-between">
          <Heading as="h3" size="md" mb={4}>
            {subscription.productName}
          </Heading>
          <Button
            colorScheme="blue"
            width={'100px'}
            disabled={loading}
            isLoading={loading}
            onClick={() => {
              subscription?.stripeSubscriptionId &&
                triggerCreateCustomerPortalSession(subscription.stripeSubscriptionId)
            }}
          >
            Manage
          </Button>
        </Flex>
        <SimpleGrid columns={2} spacing={[2, 4, 6]}>
          <DetailDisplay
            key={'Price'}
            label={'Price'}
            value={
              subscription.productPrice ? subscription.productPrice.toUpperCase() + '/month' : ''
            }
          />
          <DetailDisplay
            key={'Tokens'}
            label={'Tokens'}
            value={`${subscription.tokens > 100_000 ? 'Unlimited' : subscription.tokens}`}
          />
          <DetailDisplay key={'Start Date'} label={'Start Date'} value={subscriptionStartDate} />
          <DetailDisplay
            key={'Next Payment Date'}
            label={'Next Payment Date'}
            value={billingPeriodEndDate}
          />
        </SimpleGrid>
      </VStack>
    </Fragment>
  )
}
