import { Button, chakra, Heading, Input, SimpleGrid, Wrap } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../app/typedReduxHooks'
import { RootState } from '../../../app/store'
import { STATUS } from '../../../constants'
import InputField from '../../../components/InputField'
import { capitalizeFirstLetter } from '../../../utils/text-formatter'
import { updateOrganizationData } from '../OrganizationSlice'
import DetailDisplay from './DetailDisplay'

const FormWithStyle = chakra('form')

const OrganizationDetails = ({ isAbleToManageOrg }: { isAbleToManageOrg?: boolean }) => {
  const dispatch = useAppDispatch()
  const organization = useSelector((state: RootState) => state.organization?.data)
  const { updateOrganizationProfile } = useSelector(
    (state: RootState) => state.organization?.status
  )
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  if (!organization) {
    return null
  }

  const handleUpdate = (value: any) => {
    if (organization.id) {
      dispatch(updateOrganizationData({ id: organization.id, ...value }))
    }
  }

  return (
    <div>
      <Heading as="h3" size="md" mb={4}>
        Organization Profile
      </Heading>

      <FormWithStyle onSubmit={handleSubmit(handleUpdate)} w={['100%', '44%']}>
        <Wrap spacing={3}>
          <InputField label="Name" field="name" errors={errors}>
            <Input
              disabled={!isAbleToManageOrg}
              placeholder="Name"
              {...register('name', { value: organization.name || '' })}
            />
          </InputField>
          {isAbleToManageOrg && (
            <Button
              colorScheme="blue"
              size="sm"
              type="submit"
              isLoading={updateOrganizationProfile === STATUS.PENDING}
              loadingText="Submitting"
            >
              Submit
            </Button>
          )}
        </Wrap>
      </FormWithStyle>

      <Heading as="h4" size="sm" mb={4} mt={5}>
        Clinic Details
      </Heading>
      {organization.clinic && (
        <SimpleGrid columns={[1, 2]} spacingY={[2, 4]} spacingX={[1, 20]}>
          {Object.entries(organization.clinic).map(([label, value]) => (
            <DetailDisplay
              key={label}
              label={capitalizeFirstLetter(label)}
              value={value as string}
            />
          ))}
        </SimpleGrid>
      )}
    </div>
  )
}

export default OrganizationDetails
