import { AlertIcon, Alert, Text, useDisclosure } from '@chakra-ui/react'
import { FC, Fragment, FunctionComponent, useEffect, useState } from 'react'
import CSVFileInput from '../../../common/components/CSVFileInput'
import Modal from '../../../common/components/Modal'
import { Patient } from '../../../types'
import { ImportPatientPreviewTable } from './ImportPatientPreviewTable'
import {
  transformAndValidatePatients,
  convertToImportData,
  filiterOutDuplicatePatients,
  filiterOutIHIduplicatePatients,
} from '../services'
import { PatientQuantityExceedErrorBox } from './PatientQuantityExceedLimistError'
import useApi from '../../../common/utils/api/useApi'
import {
  IhiGender,
  PatientClient,
  PatientIhiSearchErrorResponse,
  PatientIhiSearchSuccessfulResponse,
} from '../PatientContextSlice'
import { CheckIHILoadingBox } from './CheckIHIloadingBox'
import { useAppSelector } from '../../../app/typedReduxHooks'
import {
  BatchCreatePatientsResponseItem,
  CreatePatientRequest,
  useBatchCreatePatientsMutation,
} from '../../../services/patients'
import { ImportPatientHelpGuide } from './ImportPatientHelpGuide'
import { convertPatientDataToXlsx } from '../utils/convertPatientDataToXlsx'
import { ImportedPatient } from '../../../types/patient'
import { PatientsValidateInfoAlert } from './PatientsValidateInfoAlert'

type ImportPatientModalProps = {
  children: FunctionComponent<{
    openModal: () => void
  }>
}

export const ImportPatientsModal: FC<ImportPatientModalProps> = ({
  children: ChildrenComponent,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const prescriberState = useAppSelector((state) => state.prescriber)
  const { currentClinic } = useAppSelector((state) => state.clinic)

  const [improtedPatients, setImprotedPatients] = useState<Partial<Patient>[]>([])
  const [invalidPatientsData, setInvalidPatientsData] = useState<ImportedPatient[]>([])
  const [invalidPatientsCount, setInvalidPatientsCount] = useState<number>(0)
  const [checkIHIloading, setCheckIHILoading] = useState(false)
  const [isQuantityExceedsLimit, setQuantityExceedsLimit] = useState(false)
  const [disableImport, setDisableImport] = useState(false)
  const [importPatientsResult, setImportPatientsResult] = useState<
    BatchCreatePatientsResponseItem[]
  >([])
  const [
    batchCreatePatients,
    { data: batchCreatePatientsResponse, error: batchCreatePatientsError, isLoading },
  ] = useBatchCreatePatientsMutation()

  const { trigger: checkIHI } = useApi(PatientClient.checkIhi)

  useEffect(() => {
    setDisableImport(
      isQuantityExceedsLimit ||
        improtedPatients.length === 0 ||
        isLoading ||
        importPatientsResult.length !== 0
    )
  }, [isQuantityExceedsLimit, improtedPatients, isLoading, importPatientsResult])

  useEffect(() => {
    if (batchCreatePatientsResponse) {
      setImportPatientsResult(batchCreatePatientsResponse)
    } else {
      setImportPatientsResult([])
    }
  }, [batchCreatePatientsResponse])

  const validateIHIForPatients = async (
    formattedPatients: Partial<Patient>[]
  ): Promise<{ ihiValidPatients: Partial<Patient>[]; ihiInvalidPatients: Partial<Patient>[] }> => {
    const chunkSize = 50
    const ihiValidPatients: Partial<Patient>[] = []
    const ihiInvalidPatients: Partial<Patient>[] = []
    for (let i = 0; i < formattedPatients.length; i += chunkSize) {
      const chunk = formattedPatients.slice(i, i + chunkSize)
      const chunkIHIResults = await Promise.all(
        chunk.map(
          (patient) =>
            checkIHI({
              GivenName: patient.given_names ?? '',
              FamilyName: patient.family_name ?? '',
              DOB: patient.date_of_birth ?? '',
              Gender: patient.gender as IhiGender,
              MedicareIRN: patient.medicare_irn ?? '',
              MedicareCardNumber: patient.medicare_no ?? '',
              DvaFileNumber: patient.dva_file_no ?? '',
              HpiiNumber: prescriberState.prescriber?.hpii ?? '',
              ClinicId: currentClinic?.id ?? '',
            }) as Promise<PatientIhiSearchErrorResponse | PatientIhiSearchSuccessfulResponse>
        )
      )
      chunkIHIResults.forEach((response, index) => {
        const patient = chunk[index]
        if (response) {
          const { IHI, HIStatus, RecordStatus } = response as PatientIhiSearchSuccessfulResponse
          if (IHI && HIStatus && RecordStatus === 'Verified') {
            patient.ihi_number = IHI
            patient.ihi_status = HIStatus
            patient.record_status = RecordStatus
            ihiValidPatients.push(patient)
          } else {
            ihiInvalidPatients.push(patient)
          }
        } else {
          ihiInvalidPatients.push(patient)
        }
      })
    }
    return { ihiValidPatients, ihiInvalidPatients }
  }

  const handleFileLoad = async (data: Partial<Patient>[]) => {
    if (data.length > 300) {
      setQuantityExceedsLimit(true)
      return
    }
    const { formattedPatients, invalidPatients } = transformAndValidatePatients(data)

    const { uniqueInfoPatients, duplicateInfoPatients } =
      filiterOutDuplicatePatients(formattedPatients)

    // IHI check
    setCheckIHILoading(true)
    const { ihiValidPatients, ihiInvalidPatients } = await validateIHIForPatients(
      uniqueInfoPatients
    )
    setCheckIHILoading(false)

    //Filter out duplicate data
    const { uniqueIHIPatients, duplicateIHIPatients } =
      filiterOutIHIduplicatePatients(ihiValidPatients)

    //set data
    const invalidImportedPatients = invalidPatients.concat(convertToImportData(ihiInvalidPatients))
    setInvalidPatientsData(invalidImportedPatients)
    setInvalidPatientsCount(
      invalidPatients.length +
        ihiInvalidPatients.length +
        duplicateIHIPatients.length +
        duplicateInfoPatients.length
    )
    setImprotedPatients(uniqueIHIPatients)
  }
  const importPatinets = () => {
    batchCreatePatients({
      prescriberId: prescriberState.prescriber?.id,
      patients: improtedPatients as CreatePatientRequest[],
    })
  }
  const resetModal = () => {
    setQuantityExceedsLimit(false)
    setInvalidPatientsCount(0)
    setImprotedPatients([])
    setImportPatientsResult([])
    setCheckIHILoading(false)
    setDisableImport(true)
  }

  const handleOnClose = () => {
    resetModal()
    onClose()
  }

  return (
    <Fragment>
      <ChildrenComponent openModal={onOpen} />
      <Modal
        isOpen={isOpen}
        title={'Import Patients'}
        onClose={handleOnClose}
        actions={[
          { name: 'Reset', action: resetModal },
          {
            name: 'Import',
            action: importPatinets,
            isLoading,
            isDisabled: disableImport,
          },
        ]}
      >
        {checkIHIloading ? (
          <CheckIHILoadingBox />
        ) : (
          <Fragment>
            {isQuantityExceedsLimit ? (
              <PatientQuantityExceedErrorBox />
            ) : (
              <Fragment>
                {invalidPatientsCount > 0 && (
                  <PatientsValidateInfoAlert
                    validCount={improtedPatients?.length}
                    invalidCount={invalidPatientsCount}
                    uniqueInvalidCount={invalidPatientsData.length}
                    downloadInvalidPatient={() =>
                      convertPatientDataToXlsx(
                        invalidPatientsData,
                        'invalid-patients(without duplicate patients)'
                      )
                    }
                  />
                )}
                {!!batchCreatePatientsError && (
                  <Alert status="error">
                    <AlertIcon />
                    <Text>Oops! Something went run!</Text>
                  </Alert>
                )}
                {improtedPatients.length > 0 ? (
                  <ImportPatientPreviewTable
                    patients={improtedPatients}
                    response={importPatientsResult}
                  />
                ) : (
                  <CSVFileInput onFileLoad={handleFileLoad} />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
        <ImportPatientHelpGuide />
      </Modal>
    </Fragment>
  )
}
