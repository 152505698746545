import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ApiClient from '../../utils/api/ApiClient'
import { Organization, OrganizationSetting } from '../../types'
import { STATUS } from '../../constants'
import { selectClinic } from '../NewScriptPage/ScriptForm/ClinicContextSlice'

interface OrganizationState {
  data?: Partial<Organization>
  status: {
    fetchById: STATUS
    updateOrganizationProfile: STATUS
    updateOrganizationSetting: STATUS
  }
}

export const fetchOrganizationData = createAsyncThunk(
  'organization/fetchById',
  async (organizationId: string, { dispatch }) => {
    const response = await ApiClient.get<Organization>(`bff/organizations/${organizationId}`)
    const organization = response.data
    const clinic = organization.clinic
    clinic && dispatch(selectClinic(clinic))
    return organization
  }
)

export const updateOrganizationData = createAsyncThunk(
  'organization/updateOrganizationProfile',
  async (newOrganization: Partial<Organization>) => {
    const { id, ...data } = newOrganization
    const result = await ApiClient.patch(`organization/${id}`, data)
    return result.data
  }
)

export const updateOrganizationSettingsData = createAsyncThunk(
  'organization/updateOrganizationSettingsProfile',
  async (newOrganizationSettings: Partial<OrganizationSetting & { id: string }>) => {
    const { id, ...data } = newOrganizationSettings
    const result = await ApiClient.patch(`organization/${id}/settings`, data)
    return result.data
  }
)

export const createOrganizationByAdmin = async (newOrganizationData: any) => {
  const { data } = await ApiClient.post('organization', newOrganizationData)
  return data
}

type createOrganizationProps = {
  clinicId: string
  email: string
  givenName: string
  familyName: string
  faxNumber?: string
}
export const createOrganization = async (newOrganizationData: createOrganizationProps) => {
  const { data } = await ApiClient.post('bff/organizations', newOrganizationData)
  return data
}

const OrganizationSlice = createSlice<OrganizationState, Record<string, any>>({
  name: 'organization',
  initialState: {
    status: {
      fetchById: STATUS.DEFAULT,
      updateOrganizationProfile: STATUS.DEFAULT,
      updateOrganizationSetting: STATUS.DEFAULT,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationData.fulfilled, (state, { payload }) => {
        state.data = payload
        state.status = {
          ...state?.status,
          fetchById: STATUS.SUCCESS,
        }
      })
      .addCase(fetchOrganizationData.rejected, (state) => {
        state.status = {
          ...state?.status,
          fetchById: STATUS.FAILURE,
        }
      })
      .addCase(fetchOrganizationData.pending, (state) => {
        state.status = {
          ...state?.status,
          fetchById: STATUS.PENDING,
        }
      })
      .addCase(updateOrganizationData.fulfilled, (state, { payload }) => {
        state.data = payload
        state.status = {
          ...state?.status,
          updateOrganizationProfile: STATUS.SUCCESS,
        }
      })
      .addCase(updateOrganizationData.pending, (state) => {
        state.status = {
          ...state?.status,
          updateOrganizationProfile: STATUS.PENDING,
        }
      })
      .addCase(updateOrganizationSettingsData.fulfilled, (state, { payload }) => {
        state.status = {
          ...state?.status,
          updateOrganizationSetting: STATUS.SUCCESS,
        }

        state.data = {
          ...state.data,
          settings: payload,
        }
      })
      .addCase(updateOrganizationSettingsData.pending, (state) => {
        state.status = {
          ...state?.status,
          updateOrganizationSetting: STATUS.PENDING,
        }
      })
  },
})

export default OrganizationSlice.reducer
