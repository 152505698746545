import { Prescriber } from '../../types'
import { PrescriberCreation } from '../../types/prescriber'

export type PrescriberInService = Omit<Prescriber, 'qualifications'> & { qualifications: string[] }

export const transformPrescriberForService = (data: Prescriber | PrescriberCreation) => {
  const { qualifications, ...restData } = data

  if (qualifications) {
    return {
      ...restData,
      qualifications: qualifications.split(',').map((qualification) => qualification.trim()),
    }
  }

  return restData
}

export const transformPrescriberForUI = (data: PrescriberInService): Prescriber => {
  const { qualifications, ...restData } = data

  if (qualifications?.length > 0) {
    return {
      ...restData,
      qualifications: qualifications.join(','),
    }
  }

  return restData
}
