import React, { Fragment, useEffect } from 'react'
import { Divider, Text, VStack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../app/typedReduxHooks'
import { RootState } from '../app/store'
import { BillingClient } from '../pages/Billing/BillingSlice'
import { titleMsPrescribersId } from '../pages/NewScriptPage/SendScriptPanel/PaperPrescription'

export const UserSummaryPanel: React.FC<unknown> = () => {
  const dispatch = useAppDispatch()

  const prescriber = useAppSelector((state) => state.prescriber.prescriber)
  const admin = useAppSelector((state) => state.organizationAdmin.profile)
  const subscription = useAppSelector((state: RootState) => state.billing?.subscription.data)
  const clinic = useAppSelector((state) => state.clinic.currentClinic)

  const organizationId = useAppSelector(
    (state: RootState) => state.prescriber?.prescriber?.organization.id
  )
  useEffect(() => {
    if (!subscription && organizationId && prescriber) {
      dispatch(BillingClient.getSubscription({ prescriberId: prescriber?.id, organizationId }))
    }
  }, [organizationId, dispatch])

  const prescriberTitle = titleMsPrescribersId.includes(prescriber?.id ?? '') ? 'Ms' : 'Dr'

  return (prescriber || admin) && clinic ? (
    <VStack width="260px" spacing={2} alignItems="stretch">
      <Text color="white" paddingStart="16px">
        {prescriber
          ? `${prescriberTitle} ${prescriber.givenNames} ${prescriber.familyName}`
          : `${admin?.givenName} ${admin?.familyName}`}
      </Text>

      <Text color="white" paddingStart="16px">
        Clinic: {clinic.name}
      </Text>
      {prescriber && (
        <Fragment>
          <Text color="white" paddingStart="16px">
            Prescriber No: {prescriber.prescriberNumber}
          </Text>
          {subscription?.tokens && subscription?.tokens < 10_000 && (
            <Text color="white" paddingStart="16px">
              Remaining Tokens: {subscription?.remainingTokens}
            </Text>
          )}
          {prescriber.paperPrescriptionOnly && (
            <>
              <Divider />
              <Text fontSize="sm" color="red" paddingStart="16px">
                PAPER PRESCRIPTION ONLY
              </Text>
            </>
          )}
        </Fragment>
      )}
    </VStack>
  ) : null
}
