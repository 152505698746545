import ApiClient from '../../utils/api/ApiClient'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Subscription } from '../../types/subscription'

export enum BillErrorCode {
  NO_SUBSCRIPTION = 'NO_SUBSCRIPTION',
  SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
  NO_REMAINING_TOKENS = 'NO_REMAINING_TOKENS',
  EXCEED_SEAT_LIMIT = 'EXCEED_SEAT_LIMIT',
}

type BillingError = {
  code: BillErrorCode
}

type BillingState = {
  subscription: {
    data?: Subscription
    error?: BillingError
  }
}

type SubscriptionValidateResponse = {
  active: boolean
}

type SubscriptionValidateRequest = {
  organizationId: string
  prescriberId: string
}

type GetSubscriptionRequest = {
  organizationId: string
  prescriberId: string
}

type CancelSubscriptionRequest = {
  subscriptionId: string
  organizationId: string
}

export const BillingClient = {
  createCheckoutSession: async (pricePlan: string): Promise<string> => {
    const { data } = await ApiClient.post('bff/billing/checkout-session', {
      pricePlan,
      successfulUrl: `${process.env.REACT_APP_WEBSITE_BASE_URL}/new-script`,
      cancelUrl: `${process.env.REACT_APP_WEBSITE_BASE_URL}/price-plans`,
    })
    return data
  },
  createCustomerPortalSession: async (stripeSubscriptionId: string): Promise<string> => {
    const { data } = await ApiClient.post('bff/billing/customer-portal-session', {
      stripeSubscriptionId,
      returnUrl: `${process.env.REACT_APP_WEBSITE_BASE_URL}/settings`,
    })
    return data
  },
  checkSubscriptionActive: async ({
    organizationId,
    prescriberId,
  }: SubscriptionValidateRequest): Promise<SubscriptionValidateResponse> => {
    const query = new URLSearchParams({
      organizationId,
      prescriberId,
    })
    const { data } = await ApiClient.get(`bff/billing/validate-subscription?${query.toString()}`)
    return data
  },
  getSubscription: createAsyncThunk(
    'billing/getSubscription',
    async ({ prescriberId, organizationId }: GetSubscriptionRequest, thunkAPI) => {
      try {
        const query = new URLSearchParams({
          prescriberId,
          organizationId,
        })
        const { data } = await ApiClient.get(`bff/billing/subscription?${query.toString()}`)
        return data as Subscription
      } catch (e: any) {
        if (!e?.response) {
          throw e
        }

        return thunkAPI.rejectWithValue(e.response.data)
      }
    }
  ),
  cancelSubscription: async ({ subscriptionId, organizationId }: CancelSubscriptionRequest) => {
    const { data } = await ApiClient.put('bff/billing/subscription/cancel', {
      subscriptionId,
      organizationId,
    })
    return data
  },
  reactivateSubscription: async ({ subscriptionId, organizationId }: CancelSubscriptionRequest) => {
    const { data } = await ApiClient.put('bff/billing/subscription/re-activate', {
      subscriptionId,
      organizationId,
    })
    return data
  },
}

const billingSlice = createSlice<BillingState, Record<string, any>>({
  name: 'billing',
  initialState: {
    subscription: {
      data: undefined,
      error: undefined,
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(BillingClient.getSubscription.fulfilled, (state, { payload }) => {
        state.subscription.data = payload
        state.subscription.error = undefined
        if (payload.remainingTokens === 0) {
          state.subscription.error = { code: BillErrorCode.NO_REMAINING_TOKENS }
        }
        if (payload.hasEnoughSeats === false) {
          state.subscription.error = { code: BillErrorCode.EXCEED_SEAT_LIMIT }
        }
      })
      .addCase(BillingClient.getSubscription.rejected, (state, { payload }) => {
        state.subscription.data = undefined
        state.subscription.error = { code: (payload as BillingError)?.code }
      })
  },
})

export default billingSlice.reducer
