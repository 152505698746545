import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationAdmin } from '../../types'
import ApiClient from '../../utils/api/ApiClient'
import { AxiosResponse } from 'axios'

export interface OrganizationAdminFormData {
  email: string
  givenName: string
  familyName: string
  faxNumber?: string
}

interface OrganizationAdminCreationState {
  organizationAdminCreationPayload: Partial<OrganizationAdminFormData> | null
  profile: Partial<OrganizationAdmin> | null
}

const initialState: OrganizationAdminCreationState = {
  organizationAdminCreationPayload: null,
  profile: null,
}

export const OrganizationAdminClient = {
  updateAdminProfile: async ({
    id,
    data,
  }: {
    id: string
    data: OrganizationAdminFormData
  }): Promise<AxiosResponse<{ id: string }>> => {
    return await ApiClient.patch(`/bff/organization-admin/${id}`, data)
  },
  getAdminProfile: async ({ id }: { id: string }): Promise<AxiosResponse<OrganizationAdmin>> => {
    return await ApiClient.get(`/bff/organization-admin/${id}`)
  },
}

const organizationAdminSlice = createSlice({
  name: 'organizationAdmin',
  initialState,
  reducers: {
    setOrganizationAdmin: (
      state,
      { payload: organizationAdmin }: PayloadAction<OrganizationAdmin>
    ) => {
      state.profile = organizationAdmin
      localStorage.setItem('organizationAdmin', JSON.stringify(organizationAdmin))
    },
    setOrganizationAdminCreationPayload: (
      state,
      { payload }: PayloadAction<Partial<OrganizationAdminFormData | null>>
    ) => {
      state.organizationAdminCreationPayload = payload ?? null
    },
  },
})

export const { setOrganizationAdminCreationPayload, setOrganizationAdmin } =
  organizationAdminSlice.actions

export default organizationAdminSlice.reducer
